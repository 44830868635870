import * as React from 'react';
import { Button, FormGroup, InputGroup, Popover } from '@patternfly/react-core';
import { QuestionCircleIcon, TimesCircleIcon } from '@patternfly/react-icons';
import Tags from '@yaireo/tagify/dist/react.tagify';

import { ShareQueryContainer } from '../ShareQuery/ShareQueryContainer';
import { popoverIconClassName } from '../SearchForm';
import { ColorPalette } from '../../Utils/Color';
import { style } from 'typestyle';
import '@yaireo/tagify/dist/tagify.css';
import { SearchElement, SearchElementType } from '../../Types/Search';
import { SelectedSearchElement } from '../../Pages/Hooks/useSearchElementStash';

interface SearchKeywordsProps {
    searchElements: Array<SelectedSearchElement>;
    addKeyword: (element: SearchElement) => void;
    removeKeyword: (element: SearchElement) => void;
    query: string;
}

const tagsInputGroupClassName = style({
    $nest: {
        '& .tags-input': {
            flexGrow: 1
        }
    }
});

const placeholderKeywords = 'italy, genome, ace2, pregnancy';
const id = 'show-query';

const infoKeywordsHelp = () => {
    return (
        <Popover
            bodyContent={ <div>
                ← Select a concept from in the left sidebar<br></br>
                or<br></br>
                - Enter keywords such as:&nbsp;
                <em>{ placeholderKeywords }.</em>
            </div> }
            aria-label="Popover with Link"
            closeBtnAriaLabel="Close Popover with Link"
            position="bottom"
        >
            <QuestionCircleIcon className={ popoverIconClassName } />
        </Popover>
    );
};

const template = function(this: any, value: any): any {
    return `<tag 
                title='${ value.displayName }' 
                contenteditable='false'
                spellcheck="false"
                class='tagify__tag ${value.class ? value.class : ''}'
                style="${value.color !== undefined ? '--tag-bg:' + ColorPalette.getColor(value.color).pastel.toString() + ';' : '' }" 
                ${this.getAttributes(value)}
            >
                <x title='remove tag' class='tagify__tag__removeBtn'></x>
                <div style="border-radius: ${value.type === 'ENTITY' ? '4px' : '16px'};">
                    <span class="tagify__tag-text">${ value.displayName }</span>
                </div>
            </tag>`;
};

export const SearchKeywords: React.FunctionComponent<SearchKeywordsProps> = (props) => {

    const values = [ JSON.stringify(props.searchElements.map(k => ({
        value: k.displayName,
        ...k
    }))) ];
    return (
        <FormGroup  label={ <span>Filter { infoKeywordsHelp() }</span> }
            fieldId={ id }
            placeholder={ placeholderKeywords }
        >
            <InputGroup className={ tagsInputGroupClassName }>
                <Tags
                    id={ id }
                    name={ id }
                    aria-describedby={ id }
                    value={ values }
                    settings={ {
                        editTags: false,
                        templates: {
                            tag: template
                        },
                        callbacks: {
                            add: (e: any) => {
                                if (!e.detail.data.id) {
                                    props.addKeyword({
                                        id: e.detail.data.value.toLowerCase(),
                                        displayName: e.detail.data.value,
                                        type: SearchElementType.STRING
                                    });
                                }
                            },
                            remove: (e: any) => {
                                return props.removeKeyword(e.detail.data);
                            }
                        }
                    } }
                />
                { props.searchElements.length !== 0 ?
                    <Button variant="control" onClick={ () =>  props.removeKeyword && props.searchElements.forEach(k => props.removeKeyword(k)) }>
                        <TimesCircleIcon/>
                    </Button> : <></> }
                <ShareQueryContainer pubmedQuery={ props.query } searchElements={ props.searchElements }/>
            </InputGroup>
        </FormGroup>
    );
};
