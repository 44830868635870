import * as React from 'react';
import {
    Button,
    ButtonVariant,
    InputGroup,
    TextInput,
    Modal,
    Stack,
    StackItem, Bullseye
} from '@patternfly/react-core';
import { buildShareQueryUrl } from '../../Utils/ShareQuery';
import { ShareIcon } from '@patternfly/react-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { style } from 'typestyle';
import { SelectedSearchElement } from '../../Pages/Hooks/useSearchElementStash';

interface ShareQueryProps {
    pubmedQuery: string;
    searchElements: Array<SelectedSearchElement>;
    isOpen: boolean;
    isCopied: boolean;
    onClose: () => void;
    onOpen: () => void;
    onCopy: () => void;
}

const copiedClassName = style({
    marginTop: 15
});

export const ShareQuery: React.FunctionComponent<ShareQueryProps> = (props) => {
    const shareQueryUrl = buildShareQueryUrl({
        pubmedQuery: props.pubmedQuery,
        searchElements: props.searchElements
    });

    return (
        <>
            <Button variant={ ButtonVariant.control } aria-label="share query" onClick={ props.onOpen }>
                Share <ShareIcon/>
            </Button>
            <Modal
                variant="small"
                title="Share query link"
                isOpen={ props.isOpen }
                onClose={ props.onClose }
            >
                <Stack>
                    <StackItem>Link to share</StackItem>
                    <StackItem>
                        <CopyToClipboard text={ shareQueryUrl } onCopy={ props.onCopy } options={ { format: 'text/plain' } } >
                            <InputGroup>
                                <TextInput isReadOnly aria-label="shared-query-url" value={ shareQueryUrl } />
                                <Button>Copy link</Button>
                            </InputGroup>
                        </CopyToClipboard>
                    </StackItem>
                    <StackItem className={ copiedClassName }>
                        <Bullseye>
                            { props.isCopied ? 'Link copied to clipboard' : ' ' }
                        </Bullseye>
                    </StackItem>
                </Stack>
            </Modal>
        </>
    );
};
