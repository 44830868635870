import * as React from 'react';
import { AnchorHTMLAttributes } from 'react';
import TreeMenu, { TreeMenuItem, TreeNodeInArray } from 'react-simple-tree-menu';
import { Title, TextInput } from '@patternfly/react-core';
import './EntitiesMenu.css';
import { SearchElement, SearchElementType } from '../Types/Search';
import { ChevronRightIcon, ChevronDownIcon } from '@patternfly/react-icons';
import { style } from 'typestyle';

export interface Entity {
    type: 'entity';
    id: string;
    name: string;
    count: number;
    words: Array<string>;
}

export interface EntityGroup {
    type: 'group';
    name: string;
    contents: Array<EntityGroup | Entity>;
}

export type Entities = ReadonlyArray<EntityGroup | Entity>;

export interface EntitiesMenuProps {
    entities: Entities;
    showEntities: Array<SearchElement>;
    addEntity: (element: SearchElement) => void;
    removeEntity: (element: SearchElement) => void;
}

const menuItemClassName = style({
    cursor: 'pointer'
});

const inputClassName = style({
    marginBottom: 10
});

const isSelected = (entities: Array<SearchElement>, id: any) => entities.findIndex(
    value => value.id === id
) !== -1;

export const NoFocusAnchor: React.FunctionComponent<AnchorHTMLAttributes<HTMLAnchorElement>> = (props) => {
    const newProps = {
        ...props,
        onClick: (ev: any) => {
            ev.target.blur();
            return props.onClick && props.onClick(ev);
        }
    };
    return <span { ...newProps }>{ props.children }</span>;
};

const countReducer = (count: number, value: EntityGroup | Entity): number => {
    if (value.type === 'entity') {
        return count + value.count;
    } else if (value.type === 'group') {
        return count + value.contents.reduce(countReducer, 0);
    } else {
        throw new Error('Invalid value found on entities' + JSON.stringify(value));
    }
};

const FIRST_AUTHOR = 'First author:';
const LAST_AUTHOR = 'Last author:';

const entityMap = (value: EntityGroup | Entity): TreeNodeInArray => {
    if (value.type === 'entity') {
        return {
            key: value.id,
            label: value.name,
            count: value.count,
            words: value.words,
            id: value.id,
            isOpen: true
        };
    } else if (value.type === 'group') {
        return {
            key: `${value.name}-group`,
            label: value.name,
            nodes: value.contents.map(entityMap),
            isOpen: true,
            count: value.contents.reduce(countReducer, 0)
        };
    } else {
        throw new Error('Invalid value found on entities' + JSON.stringify(value));
    }
};

interface EntityItemProps extends TreeMenuItem {
    label: string;
    selected: boolean;
}

export const EntityItem: React.FunctionComponent<EntityItemProps> = props => {
    const Component = React.useMemo(() => {
        if (props.selected) {
            return 'b';
        }

        return 'span';
    },  [ props.selected ]);

    const count = (props as any).count ?? 0;

    let label = props.label;

    if (label.startsWith(FIRST_AUTHOR)) {
        label = label.slice(FIRST_AUTHOR.length);
    } else if (label.startsWith(LAST_AUTHOR)) {
        label = label.slice(LAST_AUTHOR.length);
    }

    return (
        <li className={ menuItemClassName } onClick={ props.hasNodes ? props.toggleNode : props.onClick } style={ {
            paddingLeft: `${props.level + (props.hasNodes ? 0 : 1)}em`
        } }>
            <Component>
                { props.hasNodes && (props.isOpen ? <ChevronDownIcon/> : <ChevronRightIcon/>) }
                { label } { count ? ` - ${count}` : ''}
            </Component>
        </li>
    );
};

export const EntitiesMenu: React.FunctionComponent<EntitiesMenuProps> = ({ addEntity, removeEntity, showEntities, entities }) => {
    const onItemClick = React.useCallback((element) => {
        if (element.hasNodes) {
            return;
        }

        const id = element.id;
        let isInSelected = showEntities.findIndex(value => value.type === SearchElementType.ENTITY && value.id === id) !== -1;

        let entityType = SearchElementType.ENTITY;
        if  (element.parent.startsWith('Industry-group') || element.parent.startsWith('Authors-group')) {
            entityType = SearchElementType.STRING;
            isInSelected = showEntities.findIndex(value => value.type === SearchElementType.STRING && value.id === id) !== -1;
        }

        if (isInSelected) {
            removeEntity({
                id,
                type: entityType,
                displayName: element.label
            });
        } else {
            addEntity({
                id,
                type: entityType,
                displayName: element.label
            });
        }
    }, [ addEntity, removeEntity, showEntities ]);

    const data: Array<TreeNodeInArray> = React.useMemo(() => {
        return entities.map(entityMap);
    }, [ entities ]);

    const count = React.useMemo(() => entities.reduce(countReducer, 0), [ entities ]);

    return (
        <>
            <Title className="entities-title" headingLevel="h4" size="lg"><b>Concepts found:</b> { count }</Title>
            <TreeMenu
                data={ data }
                hasSearch={ true }
                disableKeyboard={ true }
                onClickItem={ onItemClick }
                initialOpenNodes={ [
                    'Industry-group',
                    'Industry-group/Company-group'
                ] }
            >
                {({ search, items }) => (
                    <div>
                        <TextInput
                            className={ inputClassName }
                            type="search"
                            onChange={ e => search && search(e) }
                            placeholder="Concept live search"
                        />
                        <ul>
                            {items.map(({ key, ...props }) => {
                                return (
                                    <EntityItem key={ key } selected={ isSelected(showEntities, props.id) }  { ...props } />
                                );
                            })}
                        </ul>
                    </div>
                )}
            </TreeMenu>
        </>
    );
};
