import {DeepReadonly} from 'ts-essentials/dist/types';
import {PapersQueryBase} from './Components/SearchForm/PapersQuery';

const apiBaseUrl = '/api';

const withBaseUrl = (path: string) => `${apiBaseUrl}/${path}`;

const getBaseUrl = () => {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}`;
};

const Config = {
    appId: 'pubskape',
    baseUrl: getBaseUrl(),
    apis: {
        urls: {
            base: apiBaseUrl,
            signin: withBaseUrl('login'),
            reset: withBaseUrl('reset/password'),
            verify: (token: string) => withBaseUrl(`verify/${token}`),
            plot: withBaseUrl('plot'),
            enrichments: withBaseUrl('enrichments'),
            papersCount: withBaseUrl('get_papers_count')
        }
    },
    placeholderSearchTerm: '',
    appQueries: [
        {
        pubmedQuery: 'kras AND (g12c OR g12d) AND "small molecule"',
        keywords: ['Amgen', 'KM Shokat', 'covalent', 'resistance', 'g12c', 'g12d'],
        entities: [
            {'id': 'C0009402', 'displayName': 'Colorectal Carcinoma'}]
        },
        {
            pubmedQuery: 'kras g12c',
            keywords: ['Amgen', 'KM Shokat', 'covalent', 'resistance'],
            entities: [
                {'id': 'C0009402', 'displayName': 'Colorectal Carcinoma'}]
        },
        {
            pubmedQuery: 'glp-1(7-37)',
            keywords: ['JF Habener', 'JJ Holst', 'DJ Drucker', 'S Mojsov', 'metformin'],
            entities: [
                {'id': 'C0011860', 'displayName': 'Diabetes mellitus Type II'},
                {'id': 'C0028754', 'displayName': 'Obesity'}]
        },
        {
            pubmedQuery: 'PSMA radioligand',
            keywords: ['complete response', 'vipivotide tetraxetan', 'M Eiber'],
            entities: [
                {'id': 'C4721208','displayName': 'Metastatic castration-resistant prostate cancer'}]
        },
        {
            pubmedQuery: 'PSMA AND (radiation OR radioligand)',
            keywords: ['complete response', 'partial response', 'M Eiber', 'Novartis'],
            entities: [
                {'id': 'C4721208','displayName': 'Metastatic castration-resistant prostate cancer'}]
        },
        {
            pubmedQuery: 'T-Cell exhaustion target',
            keywords: ['AstraZeneca', 'Genentech'],
            entities: [
                {'id': 'C1823649', 'displayName': 'TIGIT gene'},
                {'id': 'C1425514', 'displayName': 'HAVCR2 gene'},
                {'id': 'C1416771', 'displayName': 'LAG3 gene'}]
        }
    ] as ReadonlyArray<PapersQueryBase>
};

const ReadOnlyConfig: DeepReadonly<typeof Config> = Config;
export default ReadOnlyConfig;
