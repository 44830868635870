import Config from '../Config';
import { URLBuilder } from '@wizpanda/url-builder';
import { SelectedSearchElement } from '../Pages/Hooks/useSearchElementStash';
import { SearchElementType } from '../Types/Search';
import toCsv from 'csv-stringify/lib/sync';
import fromCsv from 'csv-parse/lib/sync';

interface Query {
    pubmedQuery: string;
    searchElements: Array<SelectedSearchElement>;
}

const PUBMEDQUERY_PARAM = 'q';
// Deprecated: Use SEARCH_ELEMENTS_PARAM instead
const DEPRECATED_KEYWORDS_PARAM = 'kw';
const SEARCH_ELEMENTS_PARAM = 'se';
const SHARED_PARAM = 'sharing';

const serializeSearchElements = (elements: Array<SelectedSearchElement>) => {
    const records = toCsv(elements.map(e => [ e.color, e.type, e.id, e.displayName ]));
    return records;
};

const deserializeElements = (elementsString: string): Array<SelectedSearchElement> => {
    const elements: Array<any> = fromCsv(elementsString, {
        columns: false
    }).map((e: any) => ({
        color: parseInt(e[0]),
        type: e[1],
        id: e[2],
        displayName: e[3]
    }));

    return elements.filter(e => {
        if (typeof e.displayName !== 'string' || typeof e.color !== 'number' || typeof e.id !== 'string') {
            return false;
        }

        switch (e.type) {
            case SearchElementType.STRING:
            case SearchElementType.ENTITY:
                break;
            default:
                return false;
        }

        return true;
    });
};

export const buildShareQueryUrl = (query: Query) => {
    const builder = new URLBuilder(Config.baseUrl);

    builder.appendQueryParam(PUBMEDQUERY_PARAM, query.pubmedQuery);
    builder.appendQueryParam(SEARCH_ELEMENTS_PARAM, serializeSearchElements(query.searchElements));
    builder.appendQueryParam(SHARED_PARAM, 'True');

    return builder.toString();
};

export const buildQueryParams = (query: Query) => {
    const searchParams = new URLSearchParams({
        [PUBMEDQUERY_PARAM]: query.pubmedQuery,
        [SEARCH_ELEMENTS_PARAM]: serializeSearchElements(query.searchElements)
    });

    return '?' + searchParams.toString();
};

export const loadFromUrl = (url: string): Query | undefined => {
    const builder = new URLBuilder(url);

    const pubmedQuery = builder.getQueryParam(PUBMEDQUERY_PARAM);
    const searchElementsString = builder.getQueryParam(SEARCH_ELEMENTS_PARAM);
    let searchElements = [] as Array<SelectedSearchElement>;
    if (!searchElementsString) {
        // No search elements provided, check if the deprecated keywords were provided
        const keywords = builder.getQueryParam(DEPRECATED_KEYWORDS_PARAM);
        if (keywords) {
            searchElements = keywords.split(',').map((k, i) => ({
                type: SearchElementType.STRING,
                id: k,
                displayName: k,
                color: i
            }));
        }
    } else {
        searchElements = deserializeElements(searchElementsString);
    }

    if (pubmedQuery) {
        return {
            pubmedQuery,
            searchElements
        };
    }

    return undefined;
};
