import * as React from 'react';
import { List, ListItem, ListVariant } from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { NavLinkModal } from './NavLinkModal';
import { ContactUsModal } from '../PageParts/ContactUsModal';

export const PubmedFooterBar: React.FunctionComponent<{}> = () => {

    const AComponent: React.FunctionComponent = (props) => {
        return <a { ...props }>{ props.children }</a>;
    };

    const contactUsLink =
        <NavLinkModal
            component={ AComponent }
            linkTitle="Contact Us"
            title="Contact Us"
            showClose={ true }
            disableFocusTrap={ false }
            variant="small"
        >
            <ContactUsModal />
        </NavLinkModal>
    ;

    return (
        <React.Fragment>
            <footer>
                <div className="footer-section">&nbsp;</div>
                <div className="footer-section middle">© Scicarta Inc, 2023</div>
                <div className="footer-section links">
                    <List variant={ ListVariant.inline }>
                        <ListItem>
                            { contactUsLink }
                        </ListItem>
                        <ListItem>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </ListItem>
                        <ListItem>
                            <Link to="/terms-of-use">Terms of Use</Link>
                        </ListItem>
                    </List>
                </div>
            </footer>
        </React.Fragment>
    );
};
