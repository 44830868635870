import * as React from 'react';
import {
    Dropdown,
    DropdownGroup,
    DropdownItem, DropdownToggle,
    FormGroup,
    Button,
    InputGroup,
    InputGroupText,
    Popover,
    Spinner,
    TextInput
} from '@patternfly/react-core';
import { CaretDownIcon, QuestionCircleIcon, TimesCircleIcon } from '@patternfly/react-icons';
import { popoverIconClassName } from '../SearchForm';

export interface PapersQueryProps {
    query: string;
    onChange: (papersQuery: string) => void;
    count: number | undefined;
    loading: boolean;
    placeholderSearchTerm?: string;
    isEditablePubmedQuery?: boolean;
    scheduleQuery: () => void;
    addNewSearch?: (searchTerm: string, query: PapersQueryBase) => void;
    preloadedQueries?: ReadonlyArray<PapersQueryBase>;
}

export interface PapersQueryBase {
    pubmedQuery: string;
    keywords: ReadonlyArray<string>;
    entities: ReadonlyArray<{
        id: string;
        displayName: string;
    }>;
}
interface PapersFoundProps {
    count?: number;
    loading?: boolean;
}

export const PapersFound: React.FunctionComponent<PapersFoundProps> = (props) => {
    if (props.loading) {
        return (
            <InputGroupText>
            <Spinner style={ {
                '--pf-c-spinner--Color': '#fec44f',
                '--pf-c-spinner--stroke-width-multiplier': '0.3'
            } as any }  size="sm" />
            </InputGroupText>
        );
    }

    if (props.count !== undefined) {
        return (
            <InputGroupText>
                Publications: { props.count }
            </InputGroupText>
        );
    }

    return null;
};

export const InputPapersQuery: React.FunctionComponent<PapersQueryProps> = (props) => {
    const [ isOpenDropdown, setIsOpenDropdown ] = React.useState<boolean>(false);
    const inputPuebmedQueryRef = React.createRef<HTMLInputElement>();

    const onDropdownItemClicked = React.useCallback((pubmedQuery: string, query: PapersQueryBase): void => {
        if (props.addNewSearch !== undefined) {
            props.addNewSearch(pubmedQuery, query);
        }

        props.scheduleQuery();
        setIsOpenDropdown(false);

        if (inputPuebmedQueryRef.current) {
            inputPuebmedQueryRef.current.focus();
        }
    }, [ inputPuebmedQueryRef, props ]);

    const getDropdownItem = (queryBase: PapersQueryBase) => {
        const query = queryBase.pubmedQuery;

        return (
            <DropdownItem key={ query }
                component="button"
                onClick={ () => {
                    onDropdownItemClicked(query, queryBase);
                } }
                className="item-strong-higthlight"
            >
                <span dangerouslySetInnerHTML={ { __html: query }  } />
            </DropdownItem>
        );
    };

    const dropdownItems = (props.preloadedQueries && props.preloadedQueries.length > 0) ? [
        <DropdownGroup label={ false } key="group 1">
            { props.preloadedQueries?.map(query => {
                return getDropdownItem(query);
            }) }
        </DropdownGroup>
    ] : [];

    const onKeyPressEnterRunInput = (event: any) => {
        if (event.key === 'Enter') {
            props.scheduleQuery();
        }
    };

    const onToggleDropdown = React.useCallback(()=>{
        setIsOpenDropdown(!isOpenDropdown);
    }, [ isOpenDropdown ]);

    const isReadOnly = (props.isEditablePubmedQuery !== undefined) ? !props.isEditablePubmedQuery : true;

    const inputSearch =
        <>
            <TextInput
                isRequired
                type="text"
                id="papers-query"
                name="papers-query"
                value={ props.query }
                onChange={ props.onChange }
                placeholder={ props.placeholderSearchTerm }
                onKeyPress={ onKeyPressEnterRunInput }
                isReadOnly={ isReadOnly }
                autoFocus={ true }
                ref={ inputPuebmedQueryRef  }
            />
            { props.query !== '' &&  <Button variant="control" onClick={ () => props.onChange && props.onChange('') }>
                <TimesCircleIcon/>
            </Button> }
        </>;

    const dropdownToggle =
        <DropdownToggle onToggle={ onToggleDropdown } icon={ CaretDownIcon }>
            Example queries
        </DropdownToggle>;

    if (props.preloadedQueries && props.preloadedQueries.length > 0) {
        return (
            <InputGroup>
                <Dropdown
                    toggle={ dropdownToggle }
                    isOpen={ isOpenDropdown }
                    dropdownItems={ dropdownItems }
                />
                { inputSearch }
                <PapersFound count={ props.count } loading={ props.loading }/>
            </InputGroup>
        );
    } else {
        return (
            <InputGroup>
                { inputSearch }
                <PapersFound count={ props.count } loading={ props.loading }/>
            </InputGroup>
        );
    }

};

export const PapersQuery: React.FunctionComponent<PapersQueryProps> = (props) => {

    const infoPubmedQueryHelp = () => {
        return (
            <Popover
                bodyContent={ <div> Query scientific Publications for example:  meningitis AND viral  </div> }
                aria-label="Popover with Link"
                closeBtnAriaLabel="Close Popover with Link"
                position="bottom"
            >
                <QuestionCircleIcon className={ popoverIconClassName } />
            </Popover>
        );
    };

    return (
        <FormGroup label={ <span>Search { infoPubmedQueryHelp() }</span> }
            fieldId="papers-query"
            placeholder={ props.placeholderSearchTerm }
            style={ { marginBottom: 10 } }
        >
            <InputPapersQuery
                query={ props.query }
                onChange={ props.onChange }
                count={ props.count }
                loading={ props.loading }
                placeholderSearchTerm={ props.placeholderSearchTerm }
                scheduleQuery={ props.scheduleQuery }
                addNewSearch={ props.addNewSearch }
                preloadedQueries={ props.preloadedQueries }
                isEditablePubmedQuery={ props.isEditablePubmedQuery }
            />
        </FormGroup>
    );
};
